import { divvyUrlMap } from '@app/client-config';
// The top-level (parent) route
import { isProductionClientEnv } from '@client/utils/client';

type NextData = { title?: string; description?: string };
type NextReturn = { redirect: string } | NextData;

type Route = {
  enableSSR?: boolean;
  path?: string;
  children?: Route[];
  load?: () => Promise<unknown>;
  action?:
    | (({ next }: { next: () => NextData }) => NextReturn | Promise<NextReturn>)
    | Promise<unknown>;
};

const routes: Route = {
  enableSSR: true,
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '/agent-widget',
      children: [
        {
          path: '/login-sent',
          load: () => import('./partners/pages/LoginSent/LoginSent'),
        },
        {
          path: '/error',
          load: () => import('./partners/pages/SignupError/SignupError'),
        },
        {
          path: '/success',
          load: () => import('./partners/pages/SignupSuccess/SignupSuccess'),
        },
        {
          path: '',
          load: () => import('./partners/pages/SignUp/SignUp'),
        },
        // Wildcard routes, e.g. { path: '', ... } (must go last)
        {
          enableSSR: true,
          path: '(.*)',
          action() {
            return { redirect: '/' };
          },
        },
      ],
    },
    {
      path: '(.*)',
      action() {
        if (global.window.location.hostname === 'partners.divvyeng.com') {
          return {
            redirect: divvyUrlMap.STAGE,
          };
        }
        if (global.window.location.hostname === 'localhost') {
          return {
            redirect: divvyUrlMap.DEVELOPMENT,
          };
        }
        return {
          redirect: divvyUrlMap.PRODUCTION,
        };
      },
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = route.title ? `${route.title} | Divvy` : 'Divvy';
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (!isProductionClientEnv()) {
  routes.children?.unshift({
    path: '/error',
    action: import('./error'),
  });
}

export default routes;
