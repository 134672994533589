// == TODO: THIS FILE IS NOT USING TYPESCRIPT CHECKING, PLEASE CONVERT IT ==
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import 'whatwg-fetch';

import { datadogRum } from '@datadog/browser-rum';
import queryString from 'query-string';

import clientConfig from '@app/client-config';

import { updateMeta } from './DOMUtils';
import history from './history';
import router from './router-widget';
import { clientEnv, isProductionClientEnv } from './utils/client';
import { CreateApp } from './widget-app';

if (isProductionClientEnv()) {
  datadogRum.init({
    ...clientConfig.datadog,
    env: clientEnv(),
    // Specify a version number to identify the deployed version of your application in Datadog
    version: __GIT_COMMITHASH__,
    sampleRate: 1, // Sample 1% of all sessions
    trackInteractions: true,
  });
}

// Switch off the native scroll restoration behavior and handle it manually
// https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
const scrollPositionsHistory = {};
if (window.history && 'scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

let onRenderComplete = function initialRenderComplete(initialRoute) {
  document.title = initialRoute.title;
  const elem = document.getElementById('css');
  if (elem) {
    elem.parentNode.removeChild(elem);
  }
  updateMeta('description', initialRoute.description);
  onRenderComplete = function renderComplete(route, location) {
    document.title = route.title;

    updateMeta('description', route.description);
    // Update necessary tags in <head> at runtime here, ie:
    // updateMeta('keywords', route.keywords);
    // updateCustomMeta('og:url', route.canonicalUrl);
    // updateCustomMeta('og:image', route.imageUrl);
    // updateLink('canonical', route.canonicalUrl);
    // etc.

    let scrollX = 0;
    let scrollY = 0;
    const pos = scrollPositionsHistory[location.key];
    if (pos) {
      scrollX = pos.scrollX;
      scrollY = pos.scrollY;
    } else {
      const targetHash = location.hash.substr(1);
      if (targetHash) {
        const target = document.getElementById(targetHash);
        if (target) {
          scrollY = window.pageYOffset + target.getBoundingClientRect().top;
        }
      }
    }

    // Restore the scroll position if it was saved into the state
    // or scroll to the given #hash anchor
    // or scroll to top of the page
    window.scrollTo(scrollX, scrollY);

    // Google Analytics tracking. Don't send 'pageview' event after
    // the initial rendering, as it was already sent
    // Google tag manager will now handle page load logging
  };
};

let currentLocation = history.location;

// Re-render the app when window.location changes
async function onLocationChange(location, action) {
  // Remember the latest scroll position for the previous location
  // but only if we have a location key...
  if (currentLocation.key) {
    scrollPositionsHistory[currentLocation.key] = {
      scrollX: window.pageXOffset,
      scrollY: window.pageYOffset,
    };
  }
  currentLocation = location;

  try {
    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const route = await router.resolve({
      pathname: location.pathname,
      query: queryString.parse(location.search),
    });

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return;
    }

    if (route.redirect) {
      if (
        route.redirect.indexOf('/admin') >= 0 ||
        route.redirect.startsWith('http://') ||
        route.redirect.startsWith('https://')
      ) {
        window.location.assign(route.redirect);
        return;
      }
      history.replace(route.redirect);
      return;
    }

    CreateApp(route, () => onRenderComplete(route, location));
  } catch (error) {
    if (!isProductionClientEnv()) {
      if (!__IS_DEVELOPMENT_BUILD__) {
        // this is staging
        alert(error); // eslint-disable-line no-alert
      }
      throw error;
    }

    console.error(error);

    // Do a full page reload if error occurs during client-side navigation
    if (action && currentLocation.key === location.key) {
      window.location.reload();
    }
  }
}

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
history.listen(onLocationChange);
onLocationChange(currentLocation);
