import ReactDOM from 'react-dom';

import { App } from './components/App';

export const CreateApp = (route, cb) => {
  // TODO - ReactDOM.hydrate should be more performant but we have some bugs
  const renderMethod = ReactDOM.render;

  renderMethod(<App>{route.component}</App>, document.getElementById('app'), cb);
};
